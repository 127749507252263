<template>
    <div class="card project" v-if="project">
        <div class="card-header has-background-light">
            <div class="card-header-title">
                {{ project.projectName }}
                <span class="tag is-info">{{ cityModule(project.CityModuleID) }}</span>
            </div>
        </div>
        <div class="card-image">
            <figure class="image is-16by9">
                <img src="@/assets/project-default.jpg" alt="Placeholder image">
            </figure>
        </div>
        <div class="card-content">
            <div v-if="project.description" class="mb-3">{{ project.description }}</div>
            <div class="field is-grouped is-grouped-multiline">
                <div class="control" v-if="project.ProjectTypeID">
                    <div class="tags has-addons">
                        <span class="tag is-dark">{{ $t('Type') }}</span>
                        <span class="tag">{{ projectType(project.ProjectTypeID) }}</span>
                    </div>
                </div>
                <div class="control" v-if="project.FileAccessTypeID">
                    <div class="tags has-addons">
                        <span class="tag is-dark">{{ $t('Access') }}</span>
                        <span class="tag">{{ fileAccessType(project.FileAccessTypeID) }}</span>
                    </div>
                </div>
                <div class="control" v-if="project.timeStamp">
                    <div class="tags has-addons">
                        <span class="tag is-dark">{{ $t('Date') }}</span>
                        <span class="tag">{{ formatDate(project.timeStamp) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <router-link :to="`/gis/project/${project.projectId}`" class="card-footer-item">{{ $t('View') }}</router-link>
            <a href="#" class="card-footer-item has-text-danger" @click.prevent="deleteProject">{{ $t('Delete') }}</a>
        </div>
    </div>
</template>

<script>
import {formatDate, getCityModule, getFileAccessType, getProjectType} from "@/utils";

export default {
    name: "ProjectCard",
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    methods: {
        deleteProject() {
            this.$emit('delete', this.project)
        },
        projectType(id) {
            const type = getProjectType(id)
            if (type) {
                return type.title
            }
            return ''
        },
        fileAccessType(id) {
            const type = getFileAccessType(id)
            if (type) {
                return type.title
            }
            return ''
        },
        cityModule(id) {
            const type = getCityModule(id)
            if (type) {
                return type.title
            }
            return ''
        },
        formatDate(date) {
            return formatDate(date, this.$store.state.language, 'DD-MM-YYYY')
        }
    }
}
</script>

<style scoped>

</style>
