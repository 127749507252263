<template>
    <div>
        <section class="section intro">
            <div class="level">
                <div class="level-left">
                    <h2 class="title has-text-centered-mobile is-size-5-mobile" v-if="user">{{ $t('Hello') }}, {{ user.fullName }}</h2>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <router-link to="/gis/create-project" class="button is-link">
                            <b-icon icon="plus"/>
                            <span>{{ $t('Create project') }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <h1 class="title is-size-4-mobile">{{ $t('Recent projects') }}</h1>
            <div class="columns is-mobile is-multiline" v-if="projects && projects.length">
                <div class="column is-12-mobile is-6-tablet is-4-desktop is-3-widescreen" v-for="(project, i) in projects" :key="i" v-if="i < latestProjectsCount">
                    <ProjectCard :project="project" @delete="confirmDeleteProject"/>
                </div>
            </div>
            <div v-else class="has-text-centered has-text-danger">
                {{ $t('There are no projects yet.') }}
            </div>
            <router-link to="/gis/projects" class="button is-link is-light" v-if="projects && projects.length > latestProjectsCount">{{ $t('View all projects') }} ({{ projects.length }})</router-link>
        </section>
        <b-loading v-model="isLoading"></b-loading>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {replaceFlags} from "@/utils";
import ProjectCard from "@/components/Gis/ProjectCard";

export default {
    name: 'Home',
    components: {ProjectCard},
    computed: {
        ...mapGetters({
            user: 'GET_USER',
            selectedCityModule: 'GET_CITY_MODULE'
        })
    },
    data() {
        return {
            projects: null,
            isLoading: false,
            latestProjectsCount: 8
        }
    },
    mounted() {
        document.title = this.$t('Welcome') + ' - GIS Solution'
        document.body.classList.add('home')
        this.$scrollToTop()
        this.getProjects()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Welcome') + ' - GIS Solution'
        },
        selectedCityModule() {
            this.getProjects()
        }
    },
    methods: {
        getProjects() {
            this.isLoading = true
            this.$store.dispatch('getAllProjects').then(response => {
                this.isLoading = false
                this.projects = response.data
                if (this.selectedCityModule) {
                    this.projects = this.projects.filter(
                        p => p.CityModuleID === this.selectedCityModule.cityModuleId
                    )
                }
            }).catch(error => {
                this.isLoading = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t(error.response ? error.response.data.message : error),
                    type: 'is-danger'
                })
            })
        },
        confirmDeleteProject(project) {
            this.$buefy.dialog.confirm({
                title: this.$t('Deleting project'),
                message: this.$t('Are you sure you want to <b>delete</b> this project? This action cannot be undone.'),
                confirmText: this.$t('Delete'),
                cancelText: this.$t('Cancel'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteProject(project)
            })
        },
        deleteProject(project) {
            this.isLoading = true
            this.$store.dispatch('deleteProject', project).then(response => {
                this.isLoading = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: replaceFlags(this.$t('Project %s has been deleted!'), [project.projectName]),
                    type: 'is-success'
                })
                this.getProjects()
            }).catch(error => {
                this.isLoading = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t(error.response ? error.response.data.message : error),
                    type: 'is-danger'
                })
            })
        }
    },
    destroyed() {
        document.body.classList.remove('home')
    }
}
</script>
